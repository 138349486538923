<!-- for tablet and mobile  -->
<div id="flow-menu-div" class="flowaccount-landing">
  <!-- ผลิตภัณฑ์ ------------------------------------->
  <div class="listmenu _product">
    <input
      type="radio"
      name="menutoggle"
      aria-label="listmenu product"
      onclick="onMenuToggle(this, 0)"
      [checked]="menuToggleChecked[0] === 0 ? menuToggleChecked[1] : false"
    />
    <a class="flow-menu" href="#" onClick="return false;"
      >{{ 'Navigation.Products' | translate }}
      <span class="more-icon"><i class="fas fa-angle-up"></i><i class="fas fa-angle-down"></i></span>
    </a>
    <div class="flow-submenu have-detail-last">
      <div class="arrow">&nbsp;</div>
      <div class="shadow-box">
        <a
          class="submenu detail clearfix"
          onclick="onMenuClick(this)"
          [routerLink]="[translate.currentLang]"
          routerLinkActive="current"
        >
          <div class="logo">
            <picture>
              <source srcset="https://flowaccountcdn.com/logo_favicon/flowaccount.svg" type="image/webp" />
              <img
                loading="lazy"
                src="https://flowaccountcdn.com/logo_favicon/flowaccount.svg"
                alt="{{ 'Navigation.New FlowAccount.Title' | translate }}"
                width="20"
              />
            </picture>
          </div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.New FlowAccount.Title' | translate }}
            </h2>
            <h3>{{ 'Navigation.New FlowAccount.Info' | translate }}</h3>
          </div>
        </a>
        <a
          class="submenu detail clearfix"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'mobile-pos']"
          routerLinkActive="current"
          rel="nofollow noopener"
        >
          <div class="logo">
            <picture>
              <source srcset="https://flowaccountcdn.com/new_landing/icon/MobilePOS.webp" type="image/webp" />
              <img
                loading="lazy"
                src="https://flowaccountcdn.com/new_landing/icon/MobilePOS.png"
                alt="{{ 'Navigation.MobilePOS.Title' | translate }}"
                width="20"
              />
            </picture>
          </div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.MobilePOS.Title' | translate }}
            </h2>
            <h3>{{ 'Navigation.MobilePOS.Info' | translate }}</h3>
          </div>
        </a>
        <a
          class="submenu detail clearfix"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'payroll']"
          routerLinkActive="current"
        >
          <div class="logo">
            <picture>
              <source
                srcset="https://flowaccountcdn.com/logo_favicon/flowaccount-payroll-green.png"
                type="image/webp"
              />
              <img
                loading="lazy"
                src="https://flowaccountcdn.com/logo_favicon/flowaccount-payroll-green.png"
                alt="{{ 'Navigation.FlowPayroll.Title' | translate }}"
                width="20"
              />
            </picture>
          </div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.FlowPayroll.Title' | translate }}
            </h2>
            <h3>{{ 'Navigation.FlowPayroll.Info' | translate }}</h3>
          </div>
        </a>
        <a
          class="submenu detail clearfix"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'autokey']"
          routerLinkActive="current"
        >
          <div class="logo">
            <picture>
              <source
                srcset="https://flowaccountcdn.com/AUTOKEY_LANDING/Favicon_Landing_AutoKey.png"
                type="image/webp"
              />
              <img
                loading="lazy"
                src="https://flowaccountcdn.com/AUTOKEY_LANDING/Favicon_Landing_AutoKey.png"
                alt="{{ 'Navigation.Autokey.Title' | translate }}"
                width="20"
              />
            </picture>
          </div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.Autokey.Title' | translate }}
            </h2>
            <h3 style="max-width: 300px">{{ 'Navigation.Autokey.Info' | translate }}</h3>
          </div>
        </a>
        <a class="submenu detail-last" onclick="onMenuClick(this)" [routerLink]="[language | async, 'reviews']">
          <i class="fas fa-solid fa-star star-icon-mobile"></i>{{ 'Navigation.Review' | translate }}
        </a>
      </div>
    </div>
    <hr />
  </div>
  <!-- ฟังก์ชั่น ------------------------------------->
  <div class="listmenu _function">
    <input
      type="radio"
      name="menutoggle"
      aria-label="listmenu function"
      onclick="onMenuToggle(this, 1)"
      [checked]="menuToggleChecked[0] === 1 ? menuToggleChecked[1] : false"
    />
    <a class="flow-menu" href="#" onClick="return false;"
      >{{ 'Navigation.Functions' | translate }}
      <span class="more-icon"><i class="fas fa-angle-up"></i><i class="fas fa-angle-down"></i></span>
    </a>
    <div class="flow-submenu have-detail-last">
      <div class="arrow">&nbsp;</div>
      <div class="shadow-box">
        <div>
          <a
            class="submenu detail clearfix no-description"
            onclick="onMenuClick(this)"
            [routerLink]="[language | async, 'functions', 'business']"
          >
            <div class="logo"><i class="fas fa-user-tie"></i></div>
            <div class="submenu-detail">
              <h2 class="_heading">
                {{ 'Navigation.Functions_business' | translate }}
              </h2>
              <!-- รายละเอียดของ description ขอ comment ไว้ก่อน วันนึงอาจกลับมาแสดง -->
              <!-- <h3>{{ 'Navigation.Functions_business_info' | translate }}</h3> -->
            </div>
          </a>
          <a
            class="submenu detail clearfix no-description"
            onclick="onMenuClick(this)"
            [routerLink]="[language | async, 'functions', 'accounting']"
          >
            <div class="logo"><i class="fas fa-calculator"></i></div>
            <div class="submenu-detail">
              <h2 class="_heading">
                {{ 'Navigation.Functions_accountant' | translate }}
              </h2>
              <!-- รายละเอียดของ description ขอ comment ไว้ก่อน วันนึงอาจกลับมาแสดง -->
              <!-- <h3>{{ 'Navigation.Functions_accountant_info' | translate }}</h3> -->
            </div>
          </a>
          <a
            class="submenu detail clearfix no-description"
            onclick="onMenuClick(this)"
            [routerLink]="[language | async, 'functions', 'quotation']"
          >
            <div class="logo"><i class="far fa-file-alt"></i></div>
            <div class="submenu-detail">
              <h2 class="_heading">
                {{ 'Navigation.Functions_quotation' | translate }}
              </h2>
              <!-- รายละเอียดของ description ขอ comment ไว้ก่อน วันนึงอาจกลับมาแสดง -->
              <!-- <h3>{{ 'Navigation.Functions_quotation_info' | translate }}</h3> -->
            </div>
          </a>

          <a
            class="submenu detail clearfix no-description"
            onclick="onMenuClick(this)"
            [routerLink]="[language | async, 'functions', 'receipt']"
          >
            <div class="logo"><i class="far fa-file-alt"></i></div>
            <div class="submenu-detail">
              <h2 class="_heading">
                {{ 'Navigation.Functions_receipt' | translate }}
              </h2>
              <!-- รายละเอียดของ description ขอ comment ไว้ก่อน วันนึงอาจกลับมาแสดง -->
              <!-- <h3>{{ 'Navigation.Functions_receipt_info' | translate }}</h3> -->
            </div>
          </a>

          <a
            class="submenu detail clearfix no-description"
            onclick="onMenuClick(this)"
            [routerLink]="[language | async, 'functions', 'invoice']"
          >
            <div class="logo"><i class="far fa-file-alt"></i></div>
            <div class="submenu-detail">
              <h2 class="_heading">
                {{ 'Navigation.Functions_billing_note_invoice' | translate }}
              </h2>
              <!-- รายละเอียดของ description ขอ comment ไว้ก่อน วันนึงอาจกลับมาแสดง -->
              <!-- <h3>{{ 'Navigation.Functions_billing_note_invoice_info' | translate }}</h3> -->
            </div>
          </a>

          <a
            class="submenu detail clearfix custom-margin-bottom no-description"
            onclick="onMenuClick(this)"
            [routerLink]="[language | async, 'functions', 'tax-invoice']"
          >
            <div class="logo"><i class="far fa-file-alt"></i></div>
            <div class="submenu-detail">
              <h2 class="_heading">
                {{ 'Navigation.Functions_Functions_tax_invoice' | translate }}
              </h2>
              <!-- รายละเอียดของ description ขอ comment ไว้ก่อน วันนึงอาจกลับมาแสดง -->
              <!-- <h3> {{ 'Navigation.Functions_Functions_tax_invoice_info' | translate }}</h3> -->
            </div>
          </a>

          <!-- lazada shopee -->
          <a
            class="submenu detail clearfix custom-margin-bottom no-description"
            onclick="onMenuClick(this)"
            [routerLink]="[language | async, 'functions', 'shopee-lazada']"
          >
            <div class="logo">
              <picture>
                <source
                  srcset="https://flowaccountcdn.com/new_landing/image/icons/icon_shopping_bag.webp"
                  type="image/webp"
                />
                <source
                  srcset="https://flowaccountcdn.com/new_landing/image/icons/icon_shopping_bag.png"
                  type="image/jpeg"
                />
                <img
                  src="https://flowaccountcdn.com/new_landing/image/icons/icon_shopping_bag.png"
                  title=""
                  width="13"
                  height="auto"
                  loading="lazy"
                  alt="shopee-lazada-icon flowaccount"
                />
              </picture>
            </div>
            <div class="submenu-detail">
              <h2 class="_heading">
                {{ 'Navigation.Functions_shopee_lazada' | translate }}
              </h2>
            </div>
          </a>

          <!-- flowpay-->
          <a
            class="submenu detail clearfix custom-margin-bottom no-description"
            onclick="onMenuClick(this)"
            [routerLink]="[language | async, 'functions', 'flowpay']"
          >
            <div class="logo"><i class="far fa-file-alt"></i></div>
            <div class="submenu-detail">
              <h2 class="_heading">
                {{ 'Navigation.Functions_flowpay' | translate }}
              </h2>
            </div>
          </a>
        </div>

        <a class="submenu detail-last" onclick="onMenuClick(this)" [routerLink]="[language | async, 'developers']">
          <i class="fas fa-code" style="width: 30px"></i>{{ 'Navigation.Developer' | translate }}
        </a>
      </div>
    </div>
    <hr />
  </div>
  <!-- ราคา ------------------------------------->
  <div class="listmenu">
    <a
      class="flow-menu"
      href="#"
      onclick="onMenuClick(this)"
      [routerLink]="[language | async, 'pricing']"
      routerLinkActive="current"
      >{{ 'Navigation.Price' | translate }}</a
    >
    <hr />
  </div>

  <!-- Find An Accountant ------------------------------------->
  <div class="listmenu">
    <!-- <a
      class="flow-menu"
      href="#"
      onclick="onMenuClick(this)"
      [routerLink]="[language | async, 'accountingfirm', 'businessowner']"
      routerLinkActive="current"
      >{{ 'Navigation.Find_an_accountant_menu' | translate }}</a
    > -->
    <input
      type="radio"
      name="menutoggle"
      aria-label="listmenu function"
      onclick="onMenuToggle(this, 1)"
      [checked]="menuToggleChecked[0] === 1 ? menuToggleChecked[1] : false"
    />
    <a class="flow-menu" href="#" onClick="return false;"
      >{{ 'Navigation.Service' | translate }}
      <span class="more-icon"><i class="fas fa-angle-up"></i><i class="fas fa-angle-down"></i></span>
    </a>
    <div class="flow-submenu have-detail-last">
      <div class="arrow">&nbsp;</div>
      <div class="shadow-box">
        <ng-container *ngFor="let item of menuServiceList">
          <a
            class="submenu detail clearfix no-description"
            onclick="onMenuClick(this)"
            [routerLink]="
              item.pageName2 ? [language | async, item.pageName, item.pageName2] : [language | async, item.pageName]
            "
          >
            <div class="logo"> <i class="{{ item.icon }}"></i></div>
            <div class="submenu-detail">
              <h2 class="_heading"> {{ item.heading | translate }} </h2>
              <h3>{{ item.info | translate }}</h3>
            </div>
          </a>
        </ng-container>
      </div>
    </div>
    <hr />
  </div>

  <!--  ฺBecome partner ------------------------------------->
  <div class="listmenu _academy">
    <input
      type="radio"
      name="menutoggle"
      aria-label="listmenu academy"
      onclick="onMenuToggle(this, 2)"
      [checked]="menuToggleChecked[0] === 2 ? menuToggleChecked[1] : false"
    />

    <a class="flow-menu" href="#" onClick="return false;"
      >{{ 'Navigation.Become_out_partner_submenu' | translate }}
      <span class="more-icon"><i class="fas fa-angle-up"></i><i class="fas fa-angle-down"></i></span>
    </a>
    <div class="flow-submenu have-detail-last">
      <div class="arrow">&nbsp;</div>
      <div class="shadow-box">
        <!-- Accounting firm partner  -->
        <a
          class="submenu detail clearfix"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'accountingfirm', 'become-our-partner']"
          routerLinkActive="current"
        >
          <div class="logo"><i class="fas fas fa-book-open"></i></div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.Accounting_firm_partner' | translate }}
            </h2>
            <h3>{{ 'Navigation.Accounting_firm_partner_info' | translate }}</h3>
          </div>
        </a>

        <!-- Fa for Education -->
        <a
          class="submenu detail clearfix"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'flowaccount-for-education']"
          routerLinkActive="current"
        >
          <div class="logo"><i class="fas fas fa-book-open"></i></div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.FlowAccount_for_education' | translate }}
            </h2>
            <h3>{{ 'Navigation.FlowAccount_for_education_info' | translate }}</h3>
          </div>
        </a>

        <!-- Platform Businessess -->
        <a
          class="submenu detail clearfix"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'our-integrations']"
          routerLinkActive="current"
        >
          <div class="logo"><i class="fas fas fa-book-open"></i></div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.Platform_businesses_and_service' | translate }}
            </h2>
            <h3>{{ 'Navigation.Platform_businesses_and_service_info' | translate }}</h3>
          </div>
        </a>
      </div>
    </div>
    <hr />
  </div>

  <!-- อะคาเดมี่ ------------------------------------->
  <div class="listmenu _academy">
    <input
      type="radio"
      name="menutoggle"
      aria-label="listmenu academy"
      onclick="onMenuToggle(this, 3)"
      [checked]="menuToggleChecked[0] === 3 ? menuToggleChecked[1] : false"
    />

    <a class="flow-menu" href="#" onClick="return false;"
      >{{ 'Navigation.Seminar' | translate }}
      <span class="more-icon"><i class="fas fa-angle-up"></i><i class="fas fa-angle-down"></i></span>
    </a>
    <div class="flow-submenu have-detail-last">
      <div class="arrow">&nbsp;</div>
      <div class="shadow-box">
        <!-- accounting knowledge -->
        <ng-container>
          <a
            class="submenu detail clearfix"
            href="https://flowaccount.com/blog/"
            target="_blank"
            rel="nofollow noopener"
            routerLinkActive="current"
          >
            <div class="logo">
              <picture>
                <source srcset="https://flowaccountcdn.com/new_landing/image/icons/Book-_idea.webp" type="image/webp" />
                <source srcset="https://flowaccountcdn.com/new_landing/image/icons/Book%20_idea.png" type="image/png" />
                <img
                  src="https://flowaccountcdn.com/new_landing/image/icons/Book%20_idea.png"
                  alt="idea logo"
                  title="idea logo"
                  loading="lazy"
                  width="20"
                  height="auto"
                />
              </picture>
            </div>
            <div class="submenu-detail">
              <h2 class="_heading">
                {{ 'Navigation.Accounting_knowledge_submenu' | translate }}
              </h2>
            </div>
          </a>
        </ng-container>
        <!-- seminar -->
        <a
          class="submenu detail clearfix"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'seminars']"
          routerLinkActive="current"
        >
          <div class="logo"><i class="fas fa-chalkboard-teacher"></i></div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.Seminar_SubMenu' | translate }}
            </h2>
          </div>
        </a>

        <!-- tutorials -->
        <a
          class="submenu detail clearfix"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'tutorials']"
          routerLinkActive="current"
        >
          <div class="logo"><i class="fas fa-play-circle"></i></div>
          <div class="submenu-detail">
            <h2 class="_heading">{{ 'Navigation.Videos' | translate }}</h2>
          </div>
        </a>

        <!-- ultimate -->
        <a
          class="submenu detail clearfix"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'ultimateguide']"
          routerLinkActive="current"
        >
          <div class="logo"><i class="fas fa-book-open"></i></div>
          <div class="submenu-detail">
            <h2 class="_heading">{{ 'Navigation.Ultimate' | translate }}</h2>
          </div>
        </a>

        <!-- faq -->
        <a
          class="submenu detail-last"
          href="https://flowaccount.com/faq"
          rel="nofollow noopener"
          onclick="onMenuClick(this)"
          target="_blank"
        >
          <i class="fas fa-question-circle"></i>{{ 'Navigation.Faq' | translate }}
        </a>
      </div>
    </div>
    <hr />
  </div>

  <!-- About us  ต้นปี 2022 ปรับซ่อนออกไปก่อน------------------------------------->
  <div *ngIf="false" class="listmenu _about-us">
    <input
      type="radio"
      name="menutoggle"
      aria-label="listmenu academy"
      onclick="onMenuToggle(this, 3)"
      [checked]="menuToggleChecked[0] === 3 ? menuToggleChecked[1] : false"
    />
    <a class="flow-menu" href="#" onClick="return false;"
      >{{ 'Navigation.About Us' | translate }}
      <span class="more-icon"><i class="fas fa-angle-up"></i><i class="fas fa-angle-down"></i></span>
    </a>
    <div class="flow-submenu">
      <div class="arrow">&nbsp;</div>
      <div class="shadow-box">
        <!-- Become_out_partner_submenu  -->
        <!-- TODO: path -->
        <a
          class="submenu detail clearfix no-description"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'accountingfirm', 'become-our-partner']"
          routerLinkActive="current"
        >
          <div class="logo"><i class="far fa-handshake"></i></div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.Become_out_partner_submenu' | translate }}
            </h2>
            <!-- <h3>{{ 'Navigation.Seminar_SubMenu_info' | translate }}</h3> -->
          </div>
        </a>

        <!-- About_flowaccount_submenu  -->
        <a
          class="submenu detail clearfix no-description"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'about-us']"
          routerLinkActive="current"
        >
          <div class="logo"
            ><img
              width="20"
              height="auto"
              src="https://flowaccountcdn.com/My_company/FlowAccount_40x40.png"
              alt="FlowAccount logo"
          /></div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.About_flowaccount_submenu' | translate }}
            </h2>
          </div>
        </a>

        <!-- Contact_us_submenu  -->
        <a
          class="submenu detail clearfix no-description"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'contact-us']"
          routerLinkActive="current"
        >
          <div class="logo"><i class="fas fa-phone"></i></div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.Contact_us_submenu' | translate }}
            </h2>
          </div>
        </a>

        <!-- Condition_submenu  -->
        <a
          class="submenu detail clearfix no-description"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'term-of-use']"
          routerLinkActive="current"
        >
          <div class="logo"><i class="fas fa-book"></i></div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.Condition_submenu' | translate }}
            </h2>
            <!-- <h3>{{ 'Navigation.Seminar_SubMenu_info' | translate }}</h3> -->
          </div>
        </a>

        <!-- Get_job_submenu  -->
        <a
          class="submenu detail clearfix no-description"
          onclick="onMenuClick(this)"
          [routerLink]="[language | async, 'jobs']"
          routerLinkActive="current"
        >
          <div class="logo"><i class="fa fa-edit"></i></div>
          <div class="submenu-detail">
            <h2 class="_heading">
              {{ 'Navigation.Get_job_submenu' | translate }}
            </h2>
            <!-- <h3>{{ 'Navigation.Seminar_SubMenu_info' | translate }}</h3> -->
          </div>
        </a>

        <!-- faq -->
        <!-- <a
          class="submenu detail-last"
          href="https://flowaccount.com/faq"
          rel="nofollow noopener"
          onclick="onMenuClick(this)"
          target="_blank"
        >
          <i class="fas fa-question-circle"></i>{{ 'Navigation.Faq' | translate }}
        </a> -->
      </div>
    </div>
    <hr />
  </div>

  <!-- end -->
</div>

<!-- for desktop with hover dropdown  -->
<nav class="navigation-wrapper">
  <ul id="product-tab" class="nav-bar">
    <li class="nav-bar__list">
      <div class="nav-title">
        <span> {{ 'Navigation.Products' | translate }} </span> <i class="fas fa-angle-up"></i
        ><i class="fas fa-angle-down"></i>
      </div>

      <ul class="nav-bar__list-dropdown">
        <div class="arrow-sign"></div>
        <!-- FlowAccount website -->
        <li>
          <a class="product-wrapper" [routerLink]="[translate.currentLang]" routerLinkActive="current">
            <div class="logo">
              <picture>
                <source srcset="https://flowaccountcdn.com/logo_favicon/flowaccount.svg" type="image/webp" />
                <img
                  src="https://flowaccountcdn.com/logo_favicon/flowaccount.svg"
                  alt="{{ 'Navigation.New FlowAccount.Title' | translate }}"
                  loading="lazy"
                  width="20"
                  style="margin-top: 3px"
                />
              </picture>
            </div>
            <div>
              <h2 class="_heading">
                {{ 'Navigation.New FlowAccount.Title' | translate }}
              </h2>
              <h3>{{ 'Navigation.New FlowAccount.Info' | translate }}</h3>
            </div>
          </a>
        </li>
        <!-- MobilePOS website -->
        <li>
          <a class="product-wrapper" [routerLink]="[language | async, 'mobile-pos']" routerLinkActive="current">
            <div class="logo">
              <picture>
                <source srcset="https://flowaccountcdn.com/new_landing/icon/MobilePOS.webp" type="image/webp" />
                <img
                  src="https://flowaccountcdn.com/new_landing/icon/MobilePOS.png"
                  alt="{{ 'Navigation.MobilePOS.Title' | translate }}"
                  loading="lazy"
                  width="20"
                  style="margin-top: 3px"
                />
              </picture>
            </div>
            <div>
              <h2 class="_heading">
                {{ 'Navigation.MobilePOS.Title' | translate }}
              </h2>
              <h3>{{ 'Navigation.MobilePOS.Info' | translate }}</h3>
            </div>
          </a>
        </li>
        <!-- FlowPayroll website -->
        <li>
          <a class="product-wrapper" [routerLink]="[language | async, 'payroll']" routerLinkActive="current">
            <div class="logo">
              <picture>
                <source
                  srcset="https://flowaccountcdn.com/logo_favicon/flowaccount-payroll-green.png"
                  type="image/webp"
                />
                <img
                  src="https://flowaccountcdn.com/logo_favicon/flowaccount-payroll-green.png"
                  alt="{{ 'Navigation.FlowPayroll.Title' | translate }}"
                  loading="lazy"
                  width="20"
                  style="margin-top: 3px"
                />
              </picture>
            </div>
            <div>
              <h2 class="_heading">
                {{ 'Navigation.FlowPayroll.Title' | translate }}
              </h2>
              <h3>{{ 'Navigation.FlowPayroll.Info' | translate }}</h3>
            </div>
          </a>
        </li>
        <!-- Autokey website -->
        <li>
          <a class="product-wrapper" [routerLink]="[language | async, 'autokey']" routerLinkActive="current">
            <div class="logo">
              <picture>
                <source
                  srcset="https://flowaccountcdn.com/AUTOKEY_LANDING/Favicon_Landing_AutoKey.png"
                  type="image/webp"
                />
                <img
                  src="hhttps://flowaccountcdn.com/AUTOKEY_LANDING/Favicon_Landing_AutoKey.png"
                  alt="{{ 'Navigation.Autokey.Title' | translate }}"
                  loading="lazy"
                  width="20"
                  style="margin-top: 3px"
                />
              </picture>
            </div>
            <div>
              <h2 class="_heading">
                {{ 'Navigation.Autokey.Title' | translate }}
              </h2>
              <h3>{{ 'Navigation.Autokey.Info' | translate }}</h3>
            </div>
          </a>
        </li>
        <!-- FlowAccount Review -->
        <li class="last-child-gray">
          <a class="product-wrapper" [routerLink]="[language | async, 'reviews']">
            <div class="logo">
              <i class="fas fa-solid fa-star"></i>
            </div>

            <div>
              <h2 class="_heading">{{ 'Navigation.Review' | translate }} </h2>
            </div>
          </a>
        </li>
      </ul>
    </li>
  </ul>

  <ul id="fucntion-tab" class="nav-bar">
    <li class="nav-bar__list">
      <div class="nav-title">
        <span> {{ 'Navigation.Functions' | translate }} </span> <i class="fas fa-angle-up"></i
        ><i class="fas fa-angle-down"></i>
      </div>

      <ul class="nav-bar__list-dropdown">
        <div class="arrow-sign"></div>
        <ng-container *ngFor="let item of menuFunctionList">
          <li>
            <a class="product-wrapper detail-none" [routerLink]="[language | async, 'functions', item.pageName]">
              <div class="logo">
                <picture *ngIf="item.pageName === 'shopee-lazada'">
                  <source
                    srcset="https://flowaccountcdn.com/new_landing/image/icons/icon_shopping_bag.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="https://flowaccountcdn.com/new_landing/image/icons/icon_shopping_bag.png"
                    type="image/jpeg"
                  />
                  <img
                    src="https://flowaccountcdn.com/new_landing/image/icons/icon_shopping_bag.png"
                    title=""
                    width="13"
                    height="auto"
                    loading="lazy"
                    alt="shopee-lazada-icon flowaccount"
                  />
                </picture>

                <ng-container *ngIf="item.pageName !== 'shopee-lazada'">
                  <i class="{{ item.icon }}"></i>
                </ng-container>
              </div>
              <div class="text--{{ item.pageName }}">
                <h2 class="_heading">
                  {{ item.heading | translate }}
                </h2>
              </div>
            </a>
          </li>
        </ng-container>
        <!-- FlowAccount Open Api -->
        <li class="last-child-gray">
          <a class="product-wrapper" [routerLink]="[language | async, 'developers']">
            <div class="logo">
              <i class="fas fa-code"></i>
            </div>
            <div>
              <h2 class="_heading">
                {{ 'Navigation.Developer' | translate }}
              </h2>
            </div>
          </a>
        </li>
      </ul>
    </li>
  </ul>

  <ul id="price-tab" class="nav-bar">
    <li class="nav-bar__list">
      <div class="nav-title">
        <a [routerLink]="[language | async, 'pricing']" routerLinkActive="current">
          <span>{{ 'Navigation.Price' | translate }} </span>
        </a>
      </div>
    </li>
  </ul>

  <!-- <ul id="find-accountant-tab" class="nav-bar">
    <li class="nav-bar__list">
      <div class="nav-title">
        <a [routerLink]="[language | async, 'accountingfirm', 'businessowner']" routerLinkActive="current">
          <span>{{ 'Navigation.Find_an_accountant_menu' | translate }} </span>
        </a>
      </div>
    </li>
  </ul> -->

  <ul id="find-accountant-tab" class="nav-bar">
    <li class="nav-bar__list">
      <div class="nav-title">
        <span> {{ 'Navigation.Service' | translate }} </span> <i class="fas fa-angle-up"></i
        ><i class="fas fa-angle-down"></i>
      </div>

      <ul class="nav-bar__list-dropdown">
        <div class="arrow-sign"></div>
        <ng-container *ngFor="let item of menuServiceList">
          <li>
            <a
              class="product-wrapper"
              [routerLink]="
                item.pageName2 ? [language | async, item.pageName, item.pageName2] : [language | async, item.pageName]
              "
            >
              <div class="logo">
                <i class="{{ item.icon }}"></i>
              </div>
              <div>
                <h2 class="_heading">
                  {{ item.heading | translate }}
                </h2>
                <h3>{{ item.info | translate }}</h3>
              </div>
            </a>
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>

  <ul id="become-partner-tab" class="nav-bar">
    <li class="nav-bar__list">
      <div class="nav-title">
        <span> {{ 'Navigation.Become_out_partner_submenu' | translate }} </span> <i class="fas fa-angle-up"></i
        ><i class="fas fa-angle-down"></i>
      </div>

      <ul class="nav-bar__list-dropdown">
        <div class="arrow-sign"></div>
        <ng-container *ngFor="let item of partnerList">
          <li>
            <a class="product-wrapper" [routerLink]="getDynamicRoute(item.href) | async" routerLinkActive="current">
              <div class="logo">
                <i class="{{ item.icon }}"></i>
              </div>
              <div>
                <h2 class="_heading">
                  {{ item.heading | translate }}
                </h2>
                <h3>{{ item.info | translate }}</h3>
              </div>
            </a>
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>

  <ul id="acamedy-tab" class="nav-bar">
    <li class="nav-bar__list">
      <div class="nav-title">
        <span> {{ 'Navigation.Seminar' | translate }} </span> <i class="fas fa-angle-up"></i
        ><i class="fas fa-angle-down"></i>
      </div>

      <ul class="nav-bar__list-dropdown">
        <div class="arrow-sign"></div>
        <ng-container *ngFor="let item of menuAcademyList">
          <li>
            <a
              *ngIf="item.pageName !== 'blog'"
              class="product-wrapper"
              [routerLink]="
                item.pageName === 'tutorials' ? [language | async, 'tutorials'] : [language | async, item.pageName]
              "
            >
              <div class="logo">
                <i class="{{ item.icon }}"></i>
              </div>
              <div>
                <h2 class="_heading">
                  {{ item.heading | translate }}
                </h2>
                <h3>{{ item.info | translate }}</h3>
              </div>
            </a>

            <a
              *ngIf="item.pageName === 'blog'"
              class="product-wrapper"
              href="https://flowaccount.com/blog/"
              target="_blank"
              rel="nofollow noopener"
            >
              <div class="logo">
                <picture>
                  <source
                    srcset="https://flowaccountcdn.com/new_landing/image/icons/Book-_idea.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="https://flowaccountcdn.com/new_landing/image/icons/Book%20_idea.png"
                    type="image/png"
                  />
                  <img
                    src="https://flowaccountcdn.com/new_landing/image/icons/Book%20_idea.png"
                    alt="idea logo"
                    title="idea logo"
                    loading="lazy"
                    width="20"
                    height="auto"
                  />
                </picture>
              </div>

              <div>
                <h2 class="_heading">
                  {{ item.heading | translate }}
                </h2>
                <h3>{{ item.info | translate }}</h3>
              </div>
            </a>
          </li>
        </ng-container>

        <!-- faq -->
        <li class="last-child-gray">
          <a class="product-wrapper" href="https://flowaccount.com/faq" rel="nofollow noopener" target="_blank">
            <div class="logo">
              <i class="fas fa-question-circle"></i>
            </div>
            <div>
              <h2 class="_heading">
                {{ 'Navigation.Faq' | translate }}
              </h2>
            </div>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>

<!-- วิธีการใช้งาน class ในการทำ navigation list -->
<ng-container *ngIf="false">
  วิธีการใช้งานเมนู<br />
  1. ถ้าเป็นเมนูธรรมดา<br />
  <div class="listmenu">
    <a
      class="flow-menu"
      href="#"
      onClick="return false;"
      [routerLink]="[language | async, 'pricing']"
      routerLinkActive="current"
      >{{ 'Navigation.Price' | translate }}</a
    >
    <hr />
  </div>
  <br />
  2. ถ้าเมนูนั้นมี more<br />
  <div class="listmenu _more">
    <a class="flow-menu" href="#" onClick="return false;"
      >{{ 'Navigation.More' | translate }}
      <span class="more-icon"><i class="fas fa-angle-up"></i><i class="fas fa-angle-down"></i></span>
    </a>
    <div class="flow-submenu">
      <div class="arrow">&nbsp;</div>
      <div class="shadow-box">
        <a class="submenu"><i class="fas fa-building"></i>{{ 'Navigation.About Us' | translate }}</a>
        <a class="submenu"><i class="fas fa-envelope"></i>{{ 'Navigation.Contact Us' | translate }}</a>
      </div>
    </div>
    <hr />
  </div>
  <br />
  สังเกตุ เติม _more ไปคือตามชื่อ class ห้ามซ้ำกัน เพื่อไป left ให้ flow-submenu อยู่ตรงกลาง <br />
  ส่วนอันที่เป็น more คือ flow-submenu<br />
  3. กรณีที่ more มี deatil ให้เติม class a class="submenu detail clearfix" และใช้ตาม<br />
  4. กรณีที่ more มี detail-last คือที่เป็นสีพื้นหลังเทาให้เติม div class="flow-submenu have-detail-last"
  (have-detail-last)<br />
  และตัวสุดท้ายที่สีเทาใช้ <br />
  <a class="submenu detail-last" [routerLink]="[language | async, 'upgrade-to-new-flowaccount']">
    <i class="fas fa-arrow-circle-up orange"></i>{{ 'Navigation.Migration' | translate }}
  </a>
  <br />
  โดยถ้าตัว i เป็นสี app ก็ไม่ต้องใส่ไรเพิ่ม เช่นข้างบน i สีส้มเลยใส่ i class="fas fa-arrow-circle-up orange"
</ng-container>
