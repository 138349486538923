import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { SEOService } from '@flowaccount/landing'
import { Pages } from '@landing-app/models/blog.model'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'flowaccount-mobile-pos',
  templateUrl: './mobile-pos.component.html',
  styleUrls: ['./mobile-pos.component.css'],
})
export class MobilePosComponent {
  page = Pages
  customHeader = {
    heading: 'BLOG_SWIPER_TOPIC.HEADING_MOBILE-POS',
    detail: 'BLOG_SWIPER_TOPIC.DETAIL_MOBILE-POS',
  }

  customInviteSection = {
    headingDesktopTH: 'เรียนรู้การใช้งานระบบ Mobile POS อย่างครบครันทุกฟังก์ชั่นกับเรา',
    headingDesktopEN: 'เรียนรู้การใช้งานระบบ Mobile POS อย่างครบครันทุกฟังก์ชั่นกับเรา',
    headingMobileTH: 'เรียนรู้การใช้งานระบบ Mobile POS<br>อย่างครบครันทุกฟังก์ชั่นกับเรา',
    headingMobileEN: 'เรียนรู้การใช้งานระบบ Mobile POS<br>อย่างครบครันทุกฟังก์ชั่นกับเรา',
    buttonNameTH: 'นัดสาธิตการใช้งาน',
    buttonNameEN: 'นัดสาธิตการใช้งาน',
  }
  protected langChangeSubscription: Subscription

  constructor(public translate: TranslateService, private seoService: SEOService, private titleService: Title) {
    this.setMeta()
    this.langChangeSubscription = translate.onLangChange.subscribe((event) => {
      this.setMeta()
    })
  }
  setMeta() {
    const title = this.translate.instant('metatitle-Function-mobilePOS')
    const description = this.translate.instant('metaDescription-mobilePOS')
    const imageUrl = 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_ogfb.png'
    const canonicalUrl = 'https://flowaccount.com/mobile-pos'

    this.titleService.setTitle(title)
    this.seoService.createMetaTag(title, description)
    this.seoService.createOg(imageUrl, title, description)
    this.seoService.setCanonicalUrl(canonicalUrl)
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe()
  }
}
